.card-badge {
  position: absolute;
  padding: to-rem(2) to-rem(12);
  border-radius: to-rem(20);
  color: var(--white-color);
  font-size: to-rem(12);
  font-weight: 500;
  background-color: #00af50;
  left: 30px;
  top: 30px;
  z-index: 1;

  &.card-badge-direct {
    background-color: #ea700d;
  }

  &-tooltip {
    background-color: #ea700d !important;
    color: var(--white-color) !important;
    bottom: 150% !important;
    left: 50% !important;
    width: 120px !important;
    margin-left: -60px !important;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #ea700d transparent transparent transparent;
    }
  }

  &-precentage {
    z-index: 2;
    background-color: #ea700d;
    padding: to-rem(2) to-rem(6);
    border-radius: to-rem(20);
    color: var(--white-color);
    font-size: to-rem(12);
    font-weight: 500;
    position: absolute;
    right: 30px;
    top: 30px;
    display: flex;
    gap: 0.2rem;
    p {
      padding: 0;
      margin: 0;
    }
  }
}

.card {
  border: 0px solid $border-color;
  margin-bottom: 40px;
  border-radius: $radius;
  // box-shadow   : $shadow;
  background: transparent;

  // &::before {
  //     content       : "";
  //     position      : absolute;
  //     top           : 22px;
  //     left          : 18px;
  //     right         : 18px;
  //     bottom        : -40px;
  //     z-index       : -2;
  //     background    : #E3E6EC;
  //     opacity       : 0.91;
  //     -webkit-filter: blur(86.985px);
  //     filter        : blur(86.985px);
  //     border-radius : 24px;
  // }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0px solid $border-color;
    background: transparent;
    padding: 30px 0px 20px;

    // margin      : 0px 35px;
    @include respond("xs") {
      flex-direction: column;
      align-items: start;
    }

    // @include custommq($min: 1200px, $max: 1350px) {
    //     padding: 30px 0px;
    // }
  }

  &-title {
    font-size: 18px;
    margin-bottom: 0px;
    color: $headings-color;
  }

  &.transparent {
    background: transparent;
    box-shadow: none;
    border: 0px;

    .card-header {
      border: 0px;
      padding: 0px;
    }

    .card-body {
      padding: 0px;
      background: transparent;
    }
  }

  .card-body {
    padding: 30px;
    background: $white;
    border-radius: $radius;
    box-shadow: $shadow;
    // border-radius: 15px;

    @include respond("xl") {
      padding: 20px;
    }

    // @include custommq($min: 1200px, $max: 1350px) {
    //     padding: 30px 0px !important;
    // }
    &-nft-card {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .card-footer {
    padding: to-rem(20);
    background: transparent;
    transition: background-color 0.5s;

    &:hover {
      background: #dbdbdb;
      color: $white;
      transition: background-color 0.5s;
    }
  }
}

.card-wrapper {
  background: $white;
  border-radius: $radius;
  cursor: pointer;
  margin-top: to-rem(5);

  --outline-color: #e5e5e5;

  .dark-theme & {
    background: #1d1933;

    --outline-color: var(--app-modal-dark-btn-hover);
  }

  &:hover {
    outline: to-rem(4) solid var(--outline-color);
  }

  &[aria-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.card-link {
  cursor: pointer;
}

.card-body .avatar-img {
  width: to-rem(70) !important;
  height: to-rem(70) !important;
  border-radius: 100% !important;
}

.dark-theme .card .card-body {
  background: #1d1933;
}

.dark-theme .card .card-footer {
  &:hover {
    background: var(--app-modal-dark-btn-hover);
    color: var(--bs-card-cap-color);
  }
}

.rewards-stakin-nft-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

  h3 {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 0;
  }

  p {
    font-size: 15px;
    line-height: 24px;
    color: #617eca;
    font-weight: 400;
    margin-bottom: 0;
  }
}
