.table {

    th {
        color        : $headings-color;
        border-bottom: 0px solid $border-color;
    }
}

.table-responsive-sm {
    min-width: 48rem;
}

.table-item-to-right{
  text-align: left !important;

}

.table {

    // border-collapse: separate;
    // border-spacing: 0 1rem;
    margin-bottom: 0px;

    tr {
        &:last-child {
            td {
                border-bottom: 0px solid $border-color;
            }
        }

        td,
        th {
            // background: rgba(0, 0, 0, 0.15);
            border-bottom : 1px solid $border-color;
            vertical-align: middle;
            padding       :10px 15px;
            &:last-child {
                text-align: right;
            }
        }
    }

    &-action-item {
        line-height: 25px;
        min-height: 25px;
        height: 5rem;
    }
}

.table-striped {
    tr {

        td,
        th {
            border: 0px !important;
        }
    }
}

.table-striped>tbody>tr:nth-of-type(odd) {
    td {
        &:first-child {
            border-top-left-radius   : 7px;
            border-bottom-left-radius: 7px;
        }

        &:last-child {
            border-top-right-radius   : 7px;
            border-bottom-right-radius: 7px;
        }
    }
}

.api-table {
    span {
        i {
            font-size: 20px;

            &:hover {
                color: $danger;
            }
        }
    }
}

.table-icon {
    span {
        i {
            font-size: 16px;
        }
    }
}



.dark-theme{
    .tokens-table {
      background: #131129;
    }
  }
  
  .tokens-table {
    background: #F5F6FA;
    margin-top: 15px;
    padding: 21px;
    border-radius: 16px;
    width: auto;
  }

  
  .table-responsive {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .transaction-short-info {
    padding-left: 1rem
  }
  
  .dark-theme .card-tx-header{
    background-color: #1D1933;
  }
  .dark-theme .card-activities{
    background-color: #131129;
  }

  .trim {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
  
  }
  .collapse-content{
    background-color:#f5f5f5
  }
  .dark-theme .collapse-content{
    background-color: #1D1933
  }
  /* .text-lg-right {
    text-align: right !important;
  } */
  
  .min-w-0 {
    min-width: 0;
  }
  .border-left {
    border-left: 1px solid #d6d6d6 !important;
    border-left-width: 1px !important;
    border-left-style: solid !important;
    border-left-color: rgb(214, 214, 214) !important;
  }
  .border-bottom{
    border-bottom: 1px solid #d6d6d6 !important;
      border-bottom-width: 1px !important;
      border-bottom-style: solid !important;
      border-bottom-color: rgb(214, 214, 214) !important;
  }
  .sc-results-list .result-item .transaction-icon {
    margin-left: -1.1rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    font-size: 0.9375rem;
    text-align: center;
    color: #4f4f4f;
    width: 2.1875rem;
    height: 2.1875rem;
    min-width: 2.1875rem;
    line-height: 2.1875rem;
    background: #f5f5f5;
    box-shadow: inset 0 0 3px rgb(79, 79 ,79);
  }
  .sc-results-list .result-item .result-item-content {
    margin-top: 0.4rem;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }
  .sc-results-list .result-item .col-left {
    max-width: 6.5rem;
    text-align: right;
  }
  .transactions-list .collapse, .transactions-list .collapsing {
    background: #f5f5f5;
    box-shadow: inset 0 0 3px rgb(79, 79, 79 );
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin-left: -15px;
    margin-right: -15px;
  }
  /* .w-100, .onboarding-modal .modal-content .slide, .rewards .wrapper .circle-button .circle-button-link {
    width: 100% !important;
  } */