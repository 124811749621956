.app-modal__overlay {
  position: fixed;
  z-index: var(--z-app-modal-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(to-rem(2));
  background-color: var(--app-modal-overlay-bg);
  inset: 0;
}

.app-modal__content {
  position: relative;
  display: flex;
  overflow: auto;
  max-width: min(to-rem(1340), calc(100% - (var(--container-side-padding) * 2)));
  max-height: 90vh;
  flex-direction: column;
  align-items: center;
  padding: to-rem(24) to-rem(32);
  border: to-rem(1) solid var(--primary-modal-border);
  background-color: var(--primary-modal-bg);
  border-radius: to-rem(24);
  outline: transparent;

  @media (min-width: #{to-rem(900)}) {
    padding: to-rem(28) to-rem(72);
  }
}

.dark-theme .app-modal__content {
  border: to-rem(1) solid var(--theme-main-color);
  background-color: var(--app-modal-content-bg);
}

.app-modal__close-btn {
  position: absolute;
  top: to-rem(24);
  right: to-rem(24);
}

.app-modal__close-btn-icon {
  width: to-rem(24);
  height: to-rem(24);
  color: var(--app-modal-close-btn-col) !important;
}

.small-modal {
  max-width: 500px !important;
}

.tx-modal-layout {
  width: 100%;
  font-size: to-rem(14);

  @media (min-width: #{to-rem(900)}) {
    max-width: min(to-rem(680), calc(100% - (var(--container-side-padding) * 2)));
  }
}

.tx-modal-layout__title {
  margin-bottom: to-rem(24);
}

.tx-modal-layout__token-image {
  @include aspect-ratio(1);

  overflow: hidden;
  width: to-rem(80);
  flex-shrink: 0;
  margin-bottom: to-rem(16);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: to-rem(8);

  @media (min-width: #{to-rem(900)}) {
    width: to-rem(100);
    border-radius: to-rem(12);
  }
}

.tx-modal-layout__collection-title {
  margin-bottom: to-rem(4);
  color: var(--primary-title-color);
  font-weight: 600;
}

.tx-modal-layout__token-name {
  margin-bottom: to-rem(20);

  @media (min-width: #{to-rem(900)}) {
    margin-bottom: to-rem(32);
  }
}

.tx-modal__wallet-input {
  margin-bottom: to-rem(16);

  .text-field__input {
    font-size: 16px;
  }
}

.tx-modal__copy-btn {
  padding: 0;
  border: none;
  background: transparent;
  color: var(--app-modal-close-btn-col) !important;

  &:hover {
    background: transparent !important;
    color: var(--theme-main-color) !important;
  }
}

.dark-theme .tx-modal__copy-btn {
  color: var(--primary-title-color) !important;

  &:hover {
    background: transparent !important;
    color: var(--theme-main-color) !important;
  }
}

.tx-modal-layout__divider {
  position: relative;
  width: 100%;
  margin-bottom: to-rem(20);

  @media (min-width: #{to-rem(900)}) {
    margin-bottom: to-rem(32);
  }
}

.receive-modal {
  // extra specificity
  &.receive-modal {
    width: 100%;
    padding-top: to-rem(46);

    @media (min-width: #{to-rem(900)}) {
      max-width: to-rem(760);
      padding-top: to-rem(28);
    }
  }
}

.lend-modal {
  width: 1000px;
  max-width: 1000px;
  min-height: to-rem(580);
  padding-top: to-rem(46);

  @media (max-height: #{to-rem(500)}) {
    min-height: to-rem(0);
    height: 100%;
    padding-top: to-rem(28);
  }
}

.send-price-field__input-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.send-price-field {
  display: flex;
  min-width: auto;
  max-width: to-rem(450);
  align-items: flex-start;
  //margin: 0 auto;
}

.send-price-field__price-in-dollars {
  flex-shrink: 0;
  margin-top: to-rem(10);
  margin-left: to-rem(10);
}

.qr-code-wrapper {
  padding: to-rem(20);
  margin-top: to-rem(16);
  margin-bottom: to-rem(24);
  background-color: var(--qr-code-bg);
  border-radius: to-rem(8);
}

.select-field {
  .select-field__control {
    min-height: to-rem(32);
    flex-wrap: nowrap;
    border-radius: to-rem(8);
    box-shadow: none;
    cursor: pointer;
    font-size: to-rem(14);

    @media (min-width: #{to-rem(900)}) {
      min-height: to-rem(32);
      font-size: to-rem(16);
    }
  }

  .select-field__value-container {
    // min-width: to-rem(120);
    padding: 0 to-rem(12);

    // @media (min-width: #{to-rem(900)}) {
    //   min-width: to-rem(130);
    // }
  }

  .select-field__single-value {
    margin: 0;
    color: var(--primary-modal-border);
    font-weight: 500;
  }

  .select-field__indicator-separator {
    display: none;
  }

  .select-field__dropdown-icon {
    width: to-rem(10);
    height: to-rem(5);
    //color: var(--secondary-color);
    transform: rotate(0);
    transition: transform var(--slide-transition);

    &--rotate {
      transform: rotate(-180deg);
    }
  }

  &.select-field--transparent {
    .select-field__control {
      border: none;
      background-color: transparent;
    }
  }

  &.select-field--filled {
    .select-field__control {
      border: to-rem(1) solid var(--primary-modal-border);
      background-color: var(--primary-modal-bg);
    }

    .select-field__value-container {
      padding-right: 0;
    }

    .select-field__indicators {
      padding: 0 to-rem(16);
    }
  }

  &.select-field--rounded {
    .select-field__control {
      border: none;
      background-color: transparent;
      border-radius: to-rem(24);
      font-weight: 600;
      transition: background-color var(--hover-transition);

      &:hover,
      &:focus {
        background-color: var(--app-button-secondary-bg);
      }
    }

    // .select-field__value-container {
    //   min-width: unset;
    // }

    .select-field__indicators {
      padding-right: to-rem(12);
    }
  }
}

.select-field__menu-portal {
  &.select-field__menu-portal {
    z-index: var(--z-dropdown-menu);
  }
}

.select-field,
.select-field__menu-portal {
  .select-field__menu {
    right: 0;
    min-width: to-rem(160);
    border: to-rem(1) solid var(--primary-modal-border);
    margin: 0;
    background-color: var(--primary-modal-bg);
    border-radius: to-rem(8);
    box-shadow: none;
    color: var(--primary-modal-border);

    @media (min-width: #{to-rem(900)}) {
      min-width: to-rem(180);
    }

    &--top {
      bottom: calc(100% + #{to-rem(4)});
    }

    &--bottom {
      top: calc(100% + #{to-rem(4)});
    }
  }

  .select-field__menu-list {
    padding-top: to-rem(16);
    padding-bottom: to-rem(16);
  }

  .select-field__option {
    padding: to-rem(8) to-rem(16);
    background-color: transparent;
    cursor: pointer;
    transition: background-color var(--hover-transition);

    &:hover,
    &:focus-visible,
    //&--is-focused,
    &--is-selected {
      //background-color: var(--select-field-menu-option-bg-hover);
      background-color: var(--primary-modal-border);
      color: var(--white-color);
    }
  }

  input {
    height: auto;
    min-height: unset;
  }
}

.dark-theme .select-field__menu-portal {
  .select-field__menu {
    border: to-rem(1) solid var(--select-field-border-col);
    background-color: var(--select-field-menu-bg);
    color: var(--primary-btn-color);
  }

  .select-field__option {
    padding: to-rem(8) to-rem(16);
    background-color: transparent;
    cursor: pointer;
    transition: background-color var(--hover-transition);

    &:hover,
    &:focus-visible,
    //&--is-focused,
    &--is-selected {
      background-color: var(--select-field-menu-option-bg-hover);
      //background-color: var(--primary-modal-border);
    }
  }
}

.dark-theme .select-field {
  .select-field__dropdown-icon {
    color: var(--secondary-color);
  }

  .select-field__single-value {
    margin: 0;
    color: var(--primary-btn-color);
    font-weight: 500;
  }

  &.select-field--filled {
    .select-field__control {
      border: to-rem(1) solid var(--theme-main-color);
      background-color: var(--input-field-bg);
      color: var(--primary-title-color);
    }
  }
}

.center_loader {
  margin-top: 40px;
}

.wallet_title_wrapper {
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.wallet_title {
  margin-bottom: 0;
}

.preference-body {
  height: 256px;
}

@media (max-width: 1200px) {
  .preference-body {
    height: auto;
  }
}

.flex {
  display: flex;
}

.wallet_title_wrapper {
  margin-bottom: 10px;
  justify-content: center;
}

.wallet_title,
.wallet_address {
  margin-right: 10px;
}

.copy_wallet_icon {
  width: 20px;
  height: 20px;
  background: url('/copy.png');
  background-size: cover;
  display: block;
  cursor: pointer;
}

.wallet_container {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.success_connect_wallet_icon {
  background: url('/ok.svg');
  background-size: cover;
  width: 24px;
  height: 24px;
  //display: inline-block;
}

.shown.promotion3::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  opacity: 0.6;
  z-index: -1;
}

.promotion3 {
  padding: 20px 30px;
  border-radius: 16px;
  margin-bottom: 30px;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.05);
  position: relative;
  z-index: 0;
  overflow: hidden;
  min-height: 230px;

  .promotion-detail {
    position: relative;
  }
}
.promotion3::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  opacity: 0;
  z-index: -1;
  transition: opacity 200ms;
}

.wallet_icon {
  background: url('/wallet-fill.svg');
  width: 150px;
  height: 150px;
  background-size: cover;
  fill: var(--primary-modal-border);
}

.max-w-650 {
  max-width: 650px;
}

.login-modal {
  .svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }
}