.text-field {
  display: flex;
  width: 100%;
  min-width: 0;
  flex: 1 1 auto;
  flex-flow: column wrap;
  border-radius: to-rem(8);
  font-size: to-rem(14);
  letter-spacing: normal;
  text-align: left;

  // height: auto;
  // flex-grow: 1;
  // max-width: 100%;
}

.text-field--small {
  height: 32px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-field__input-label {
  width: 100%;
  padding-bottom: to-rem(8);
  color: var(--secondary-title-color);
  cursor: pointer;
}

.dark-theme .disabled-text {
  color: var(--primary-modal-border) !important;
}

.text-field__inner {
  position: relative;
  z-index: var(--z-text-field-inner);
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 to-rem(16);
  border: 1px solid $border-color;
  background-color: var(--primary-modal-bg);
  border-radius: inherit;
  cursor: text;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-delay: 0s, 0s;
  transition-duration: 0.15s, 0.15s;
  transition-property: border-color, box-shadow;
  transition-timing-function: ease-in-out, ease-in-out;

  &.text-field__inner-medium {
    min-height: to-rem(40);
  }

  &.text-field__inner-small {
    min-height: to-rem(32);
  }

  &.no-hover {
    &:hover {
      border-color: $border-color;
    }
  }

  &:hover {
    box-shadow: none !important;
    outline: none;
    border: 0.0625rem solid var(--theme-main-color);
    //color       : $dark;
    background: $white;
  }
}

.append-text {
  color: $dark;
}

.dark-theme .append-text {
  color: white;
}

.search-field-wrapper {
  position: relative;
  z-index: var(--z-text-field-inner);
  display: flex;
  border: to-rem(1) solid var(--primary-modal-border);
  background-color: var(--primary-modal-bg);
  @media (min-width: #{to-rem(768)}) {
    width: 50%;
  }
  @media (min-width: #{to-rem(1000)}) {
    width: 30%;
  }
}

.search-field__inner {
  width: 100% !important;
  min-height: to-rem(40);
  align-items: center;
  padding: 0 to-rem(16);
  border-radius: to-rem(10);
  cursor: text;
  transition: border-color var(--hover-transition);
}

.dark-theme .search-field-wrapper {
  border: to-rem(1) solid var(--theme-main-color);
  background-color: var(--input-field-bg);
  color: var(--primary-title-color);
}

.dark-theme .text-field__inner {
  border: 0.0625rem solid var(--theme-main-color);
  background-color: var(--input-field-bg);
  color: var(--primary-title-color);

  &.no-hover {
    &:hover {
      border-color: var(--theme-main-color);
    }
  }

  &:hover {
    box-shadow: none !important;
    outline: none;
    border-color: rgba(255, 255, 255, 0.08);
    //color       : $dark;
    //background  : $white;
  }
}

.text-field__input {
  width: 100%;
  min-width: 0;
  max-width: 100%;
  min-height: auto;
  flex: 1 1 auto;
  padding: to-rem(8) 0;
  border: none;
  background-color: transparent;
  border-radius: 0;
  //color: inherit;
  font-size: to-rem(14);
}

.text-field__append-inner {
  display: flex;
  flex-shrink: 0;
  padding-left: to-rem(8);
}

$error-message-margin-top: to-rem(4);

.text-field__error-message {
  position: relative;
  z-index: var(--z-text-field-hint);
  overflow: hidden;
  max-width: 100%;
  min-height: to-rem(21);
  flex: 1 0 auto;
  padding: 0 to-rem(16);
  margin-top: $error-message-margin-top;
  color: var(--invalid-color);
  hyphens: auto;
  overflow-wrap: break-word;
  transform: translateY(calc(-100% - #{$error-message-margin-top}));
  transition: transform var(--slide-transition);
  word-break: break-word;
  word-wrap: break-word;

  &--visible {
    transform: translateY(0);
  }
}

.text-field--invalid {
  .text-field__inner {
    border-color: var(--invalid-color);
  }
}

.text-field--large {
  .text-field__inner {
    min-height: to-rem(48);
  }

  .text-field__input {
    font-size: to-rem(16);
  }
}

.svg-copy__button {
  padding: 0;
  border: none;
  background: transparent !important;
  color: var(--app-modal-close-btn-col) !important;

  &:hover {
    background: transparent !important;
    color: var(--theme-main-color) !important;
  }
}

.dark-theme .svg-copy__button {
  color: var(--primary-title-color) !important;

  &:hover {
    background: transparent !important;
    color: var(--theme-main-color) !important;
  }
}

.text-box {
  display: flex;
  max-width: 100%;
  min-height: to-rem(40);
  align-items: center;
  padding: 0 to-rem(16);
  border: to-rem(1) solid var(--primary-modal-border);
  background-color: var(--primary-modal-bg);
  border-radius: to-rem(10);
}

.text-box__input {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dark-theme .text-box {
  border: to-rem(1) solid var(--theme-main-color);
  background-color: var(--input-field-bg);
  color: var(--primary-title-color);
}
