.transaction-pending-modal {
  max-width: 600px;
  text-align: center;
  word-break: break-word;

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 0;
  }
}

.transaction-pending-modal__link {
  width: 100%;
  text-align: center;
  color: white;
  border-color: white;
  border-width: 2px;
  border-radius: 20px;
  background-color: #2c3e50;
  margin-top: 20px;
  padding: 10px 0;
  font-weight: normal;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: #34495e;
  }
}

.transaction-pending-modal__success {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 0;
    padding: 0;
  }

  h4 {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 0;
    padding: 0;
    color: var(--bs-body-color);
  }
}

.transaction-pending-modal__success_container {
  align-items: center;
  justify-content: center;
  margin-top: 8;
}

// paragraph style
.transaction-pending-modal__error {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
}
