.dividerWrapper{
  display:flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  padding: to-rem(31) to-rem(0);
  border-radius: to-rem(20);

  @media (min-width: #{to-rem(900)}) {
    padding: to-rem(31) to-rem(48);
  }


}

.dividerWrapper::before,
.dividerWrapper::after {
  //height: to-rem(1);
  //flex-grow: 1; // both lines will expand to occupy the available space
  //background-color: var(--primary-divider-color);
  //content: '';
}

.stepper{
  display:flex;
  width: 90%;
  align-items: center;
  justify-content: center;
}



//.stepper::before,
.stepper::after {
  height: to-rem(1);
  flex-grow: 1; // both lines will expand to occupy the available space
  background-color: #A080FC;
  content: '';
}

.stepperItemActive{
  display:flex;
  width: 50%;
  align-items: center;
  justify-content: center;

}


.stepperItemActive::after {
  height: to-rem(3);
  flex-grow: 1; // both lines will expand to occupy the available space
  background-color: #A080FC;
  border-radius: to-rem(20);
  content: '';
}




