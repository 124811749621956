
.navWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;

  @media (max-width: to-rem(660)) {
  margin: auto;
  }
}

.navButtonsContainer{
  display: flex;
  justify-content: space-between;
  width: to-rem(190);
}

.svgIcon{
  height: to-rem(10);
  color: var(--secondary-color);

  &.right{
    rotate: 180deg;
  }
}

button.navButton{
  padding: 0;
  border-width: 0;
  background-color: transparent;
  color: var(--secondary-color);
  cursor: pointer;

    &:hover{
        color: var(--secondary-color);
    }

  &:disabled{
    opacity: 0.5;
    cursor: default;
    //color: var(--secondary-color);
    //cursor: not-allowed;
  }
}
