.app-tabs__tab-list {
  display: flex;
  flex-wrap: nowrap;
  //border-bottom: to-rem(1) solid var(--primary-divider-color);
  margin-bottom: to-rem(24);
  //color: var(--primary-text-color);
  font-size: to-rem(16);
  font-weight: 600;


  @media (max-width: #{to-rem(470)}) {
    display: grid;
    //border-bottom: to-rem(0) solid var(--primary-divider-color);
    grid-template-columns: repeat(2, 1fr);
  }
}

.app-tabs__tab {
  position: relative;
  padding: to-rem(16) 0;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: to-rem(48);

    @media (min-width: #{to-rem(900)}) {
      margin-right: to-rem(64);
    }
  }

  &:after {
    position: absolute;
    right: 0;
    bottom: -#{to-rem(1)};
    width: 0;
    height: to-rem(1);
    background: var(--app-tabs-active-col);
    content: '';
    transition: width var(--slide-transition);
  }

  &.app-tabs__tab--selected,
  &:hover,
  &:focus-within {
    &:after {
      left: 0;
      width: 100%;
    }
  }

  &:focus {
    outline: none;
  }
}

.app-tabs__tab--selected {
  color: var(--app-tabs-active-col);
  transition: color var(--slide-transition);
}

// .app-tabs__tab--disabled {
//   color: GrayText;
//   cursor: default;
// }

.app-tabs__tab-panel {
  display: none;
}

.token-metadata-tabs__collection-desc{
  color: var(--primary-modal-border);
}

.dark-theme .token-metadata-tabs__collection-desc{
  color: var(--primary-modal-bg);
}


.app-tabs__tab-panel--selected {
  display: block;
}
