.sign-tx-modal {
  background-color: transparent !important;

  --bg-color: var(--primary-modal-bg);
  --text-color: var(--primary-text-light);
  --btn-color: var(--white-color);
  --btn-hover-color: var(--app-modal-light-btn-hover);
  --btn-border-color: var(--text-color);

  & .dapp-core-component__dappModalStyles__dappModalContent {
    background: var(--bg-color);
    box-shadow: unset;
    border: none;
    color: var(--text-color);
  }

  & #closeButton {
    background-color: var(--btn-color);
    border-color: var(--btn-color);
    color: var(--text-color);
    border: to-rem(1) solid var(--btn-border-color);

    &:hover {
      background-color: var(--btn-hover-color);
    }
  }

  .dark-theme & {
    --bg-color: var(--app-modal-content-bg);
    --text-color: var(--white-color);
    --btn-color: var(--app-modal-lend-btn-bg);
    --btn-hover-color: var(--app-modal-dark-btn-hover);
    --btn-border-color: transparent;
  }
}

.txs-toast {
  --bg-color: var(--primary-modal-bg);
  --text-color: var(--primary-text-light);
  --btn-color: var(--white-color);
  --btn-hover-color: var(--app-modal-light-btn-hover);
  --btn-border-color: var(--text-color);

  & .dapp-core-component__transactionsToastList-styles__toastWrapper {
    background: var(--bg-color) !important;
    color: var(--text-color);
  }

  .dark-theme & {
    --bg-color: var(--app-modal-content-bg);
    --text-color: var(--white-color);
    --btn-color: var(--app-modal-lend-btn-bg);
    --btn-hover-color: var(--app-modal-dark-btn-hover);
    // --btn-border-color: transparent;
  }

  .svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }
}
