.credit-card {
    background         : #126AFF;
    padding            : 20px;
    border-radius      : $radius;
    // max-width       : 400px;
    margin-bottom      : 30px;
    position           : relative;
    overflow           : hidden;

    // &::before {
    //     content       : "";
    //     position      : absolute;
    //     top           : 22px;
    //     left          : 18px;
    //     right         : 18px;
    //     bottom        : -40px;
    //     z-index       : -2;
    //     background    : #E3E6EC;
    //     opacity       : 0.91;
    //     -webkit-filter: blur(86.985px);
    //     filter        : blur(86.985px);
    //     border-radius : 24px;
    // }

    // &::after {
    //     content   : '';
    //     position  : absolute;
    //     left      : 0;
    //     right     : 0;
    //     top       : 0;
    //     bottom    : 0;
    //     background: #fff;
    //     opacity   : 0;
    //     transition: all 0.15s ease-in-out;
    // }

    // &:hover {
    //     &::after {
    //         opacity: 0.85;
    //     }

    //     .cc-info {
    //         bottom: 0;
    //     }
    // }

    .type-brand {
        display        : flex;
        justify-content: space-between;
        align-items    : center;

        h4 {
            color     : $body-color;
            // opacity: 0.75;
            font-size : 14px;
        }

        img {
            // filter: brightness(100);
            height       : 30px;
            background   : #fff;
            padding      : 4px 6px;
            border-radius: 8px;
        }
    }

    .cc-number {
        padding: 20px 0px;

        h6 {
            display     : inline-block;
            margin-right: 20px;
            color       : $white;
            font-size   : 20px;
        }
    }

    .cc-holder-exp {
        display        : flex;
        justify-content: space-between;

        h5 {
            color        : $white;
            margin-bottom: 0px;
        }

        .exp {
            color: $white;

            strong {
                color: $white;
            }
        }
    }

    &.visa {
        background: $dark;

        &:hover {
            .cc-info {
                background: $dark;
            }
        }
    }

    &.master {
        background: $primary;

        &:hover {
            .cc-info {
                background: $primary;
            }
        }
    }

    &.payoneer {
        background: $white;

        .type-brand {
            h4 {
                color: $body-color;
            }
        }

        .cc-number {
            h6 {
                color: $dark;
            }
        }

        .cc-holder-exp {
            h5 {
                color: $dark;
            }

            .exp {
                color: $dark;

                strong {
                    color: $dark;
                }
            }
        }

        &:hover {
            .cc-info {
                background: $white;
            }
        }
    }
}


.cc-info {
    // background         : #070707;
    padding            : 15px 20px 20px;
    // margin-bottom: 30px;
    border-radius      : 0px;
    // margin-top      : 30px;
    // margin: 10px;
    position           : absolute;
    bottom             : -100%;
    left               : 0;
    right              : 0;
    z-index            : 1;
    transition         : all 0.15s ease-in-out;

    p {
        margin-bottom: 0px;
        margin-top   : 5px;
        color        : rgba(255, 255, 255, 0.5);

        strong {
            color  : $white;
            opacity: 1;
        }
    }

}