.topWrapper{
  display:flex;
  justify-content: center;
  align-items: center;
  padding-top: to-rem(24);
  flex-direction: column;
}

.modalImage{
  height: to-rem(100);
  border-radius: to-rem(12);
}

.modalToken{
  padding-top: to-rem(16);
  //font-style: normal;
  //font-weight: 600;
  //font-size: to-rem(14);
  //line-height: 150%;

  //&.name{
  //  color: var(--white-color);
  //}

  &.collection{
    padding-top: to-rem(4);
    //color: var(--primary-text-color);
  }
}

//.modalTokenCollection{
//  font-style: normal;
//  font-weight: 400;
//  font-size: to-rem(14);
//  line-height: 150%;
//  color: var(--primary-text-color);
//}