.input-field>input{
  min-height: to-rem(40);
  padding: 0 to-rem(16);
  border: 1px solid $border-color;
  background-color: var(--primary-modal-bg);
  border-radius: to-rem(8);
  font-size: to-rem(14);
}

.field__input-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.drop-down{
  position: relative;
  min-height: to-rem(40);
  padding: 0 to-rem(16);
  border: 1px solid $border-color;
  background-color: var(--primary-modal-bg);
  border-radius: to-rem(8);
  font-size: to-rem(14);
}

.dark-theme .input-field>input{
  border: to-rem(1) solid var(--theme-main-color);
  background-color: var(--input-field-bg);
  color: var(--primary-title-color);
}

.dark-theme .drop-down{
  border: to-rem(1) solid var(--theme-main-color);
  background-color: var(--input-field-bg);
  color: var(--primary-title-color);
}
