.weapon-nft-modal {
  display: flex;
  // align-items: center;
  // justify-content: center;
  gap: to-rem(40);
  flex-direction: row;

  .image-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .stars {
      // dark:bg-[#131129] px-[1.8rem] py-2 rounded-full text-2xl flex items-center gap-3
      display: flex;
      align-items: center;
      justify-content: center;
      gap: to-rem(12);
      padding: to-rem(4) to-rem(28);
      border-radius: to-rem(20);
      font-size: to-rem(24);
      background-color: #f2f2f2;
      margin-top: to-rem(14);

      i {
        &.full {
          color: #ffd700 !important;
        }
      }
    }
  }

  .details-section {
    display: flex;
    flex-direction: column;
    gap: to-rem(10);
    width: 100%;

    .weapon-title {
      display: flex;
      flex-direction: column;
    }
  }

  .weapon-tag {
    padding: to-rem(6) to-rem(14);
    font-size: to-rem(18);
    background-color: #f2f2f2;
    border-radius: to-rem(14);
    width: 100%;
    text-align: center;

    p {
      margin: 0;
      padding: 0;
    }

    &.sm {
      font-size: to-rem(14);
      padding: to-rem(4) to-rem(10);
      border-radius: to-rem(10);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: to-rem(20);

    .image-section {
      width: 100%;
      margin-bottom: to-rem(20);
    }

    .details-section {
      width: 100%;
    }
  }
}

.dark-theme .weapon-nft-modal {
  .weapon-tag {
    background-color: #131129;
  }

  .stars {
    background-color: #131129;

    i {
      color: #1e1e3a;
    }
  }
}
