.section-padding {
    padding: 100px 0;

    @include respond('lg') {
        padding: 80px 0;
    }

    @include respond('md') {
        padding: 75px 0;
    }

    @include respond('sm') {
        padding: 60px 0;
    }

    @include respond('xs') {
        padding: 50px 0;
    }
}

.mt-80 {
    margin-top: 80px;
}

.mb-80 {
    margin-bottom: 80px;
}

// .vertical-line {
//     position   : relative;
//     min-height : 1000px;
//     border-left: 1px solid $border-color;
//     z-index    : 03;

//     &::before {
//         content         : "";
//         position        : absolute;
//         top             : -84px;
//         background      : $border-color;
//         width           : 1px;
//         height          : 100px;
//         left            : -1px;
//         // border-left  : 1px solid #f00;
//     }

//     &::after {
//         content   : "";
//         position  : absolute;
//         bottom    : -84px;
//         background: $border-color;
//         width     : 1px;
//         height    : 100px;
//         left      : -1px;
//     }
// }

.page-title {
    margin-bottom   : 30px;
    background      : transparent;
    border-radius   : $radius;
    // padding      : 10px;

    .page-title-content {
        // margin-top: 15px;


        h3 {
            font-size    : 24px;
            margin-bottom: 0px;
        }
    }
}



.breadcrumbs {
    a {
        color         : $text-muted;
        display       : inline-block;
        // margin-left: 15px;
        font-size     : 14px;


    }


    &.active {
        a {
            color: $white;
        }
    }

    i {
        margin     : 0px 10px;
        font-size  : 20px;
        line-height: 0;
        position   : relative;
        top        : 5px;
    }
}

.flex-grow-2 {
    flex-grow: 2;
}


// Scrollbar 
::-webkit-scrollbar {
    width: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow   : inset 0 0 5px grey;
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background   : #999;
    border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #777;
}


.c-pointer {
    cursor: pointer;
}

.trade-balance {
    position: relative;
    height  : 380px;
}

.position-value {
    position: relative;
    height  : 320px;
}

.shadow-sm {
    box-shadow: $shadow-sm;
}

.shadow-lg {
    box-shadow: $shadow-lg;
}

.bs-0 {
    box-shadow: none !important;
}



.section-title {
    margin-bottom: 75px;

    h2 {
        text-align : center;
        font-weight: 600
    }
}