.history-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  color: #614b7d !important;
  gap: 1rem;
  text-align: center;

  &.big {
    font-size: 1.5rem;
  }
}

.account-page {
  .total-balance {
    justify-content: flex-end;
    display: flex;

    &-text {
      font-size: to-rem(20);
      border: 1px solid;
      padding: to-rem(2) to-rem(10);
      border-radius: to-rem(8);
      border-color: $border-color;
    }
  }

  .token-section {
    background-color: $white;
    padding: to-rem(16);
    border-radius: to-rem(12);

    .blance-tokens-table {
      tbody {
        display: block;
        max-height: 150px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0.5em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: #6f4ef2;
          outline: 1px solid #1e1e3a;

          &:hover {
            background-color: #583ec1;
          }

          &:active {
            background-color: #3f2b8f;
          }
        }
      }

      .token-balance-buttons {
        display: flex;
        justify-content: flex-end;

        button {
          width: to-rem(150);
          font-size: to-rem(16);
          font-weight: 200;
          padding: to-rem(5) to-rem(10) !important;
        }
      }

      .token-amount {
        font-weight: 600;
      }

      p {
        font-size: to-rem(20);
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed; /* even columns width , fix width of table too*/
      }

      td {
        border-bottom: none !important;
        padding: to-rem(10) to-rem(20);

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      th {
        font-size: to-rem(16);
        border-bottom: none !important;
        color: var(--bs-card-cap-color);
        font-weight: 200;
        padding: 0;
        padding-bottom: to-rem(8);
        padding-left: to-rem(10);
        border: none;
      }
    }
  }

  .sft-section {
    background-color: $white;
    padding: to-rem(16);
    border-radius: to-rem(12);

    .sfts-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-direction: column;
      color: #614b7d;
      gap: 1rem;
    }

    .sfts-grid {
      margin-top: to-rem(16);
      padding: 0 to-rem(20);
      overflow: auto;
      padding-bottom: 25px;

      &-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .sft-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: to-rem(10) to-rem(20);
        background-color: #f2f2f2;
        border-radius: to-rem(16);
        max-width: 250px;

        &-img {
          width: 100%;
          height: to-rem(120);
          border-radius: to-rem(16);
          background-color: $border-color;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: to-rem(10);

          img {
            width: to-rem(100);
            height: to-rem(100);
          }
        }

        &-text {
          font-size: to-rem(16);
          font-weight: 200;

          &-title {
            margin-bottom: 0;
          }

          &-footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &-id {
              font-size: to-rem(12);
              font-weight: 200;
              margin-bottom: to-rem(4);
            }
          }
        }

        &-btn {
          display: flex;
          justify-content: flex-end;

          button {
            width: to-rem(150);
            font-weight: 200;
            padding: to-rem(5) to-rem(10) !important;
          }
        }
      }
    }
  }

  .transaciton-history-section {
    background-color: $white;
    padding: to-rem(16);
    border-radius: to-rem(12);

    .see-all-btn {
      display: flex;
      justify-content: center;
      margin-bottom: to-rem(16);
      cursor: pointer;
    }

    table {
      th {
        border: none;
      }

      td,
      th {
        &:last-child {
          text-align: left;
        }
      }

      td {
        border: none;
      }

      tbody {
        display: block;
        max-height: fit-content;
        overflow: auto;
      }

      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed; /* even columns width , fix width of table too*/
      }
    }
  }
}

.account-page-title {
  display: flex;

  &-text {
    font-size: to-rem(20);
    border: 1px solid;
    padding: to-rem(2) to-rem(10);
    border-radius: to-rem(8);
    border-color: $border-color;
    margin-bottom: 0;
  }
}
.dark-theme {
  .account-page {
    .token-section {
      background-color: $dcard;

      .total-balance {
        &-text {
          border-color: #6f4ef2;
        }
      }
    }

    .sft-section {
      background-color: $dcard;

      .sft-title {
        &-text {
          border-color: #6f4ef2;
        }
      }

      .sfts-grid {
        .sft-item {
          background-color: #131129;

          &-img {
            background-color: #1e1e3a;
          }
        }
      }
    }

    .transaciton-history-section {
      background-color: $dcard;
    }
  }
}

.account-page-mobile {
  position: relative;
  z-index: 1;
  margin-top: -18px;
  &-title {
    font-size: 20px;
    line-height: 30px;
    margin-left: auto;
    max-width: 254px;
    padding: 11px 5px 12px 15px;
    margin-bottom: 14px;
    position: relative;
    border-radius: 10px;
    font-weight: 500;

    &.dark-theme {
      background-color: #141128;
    }

    &.light-theme {
      background-color: #f2f2f2;
      color: #270b48;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: -1px;
      z-index: -1;
      border-radius: inherit;
      background-image: linear-gradient(
        33deg,
        #6955e7 30.37%,
        rgba(252, 186, 126, 0.8) 84.77%
      );
    }
  }
  .token-wrapper {
    margin-bottom: 16px;
    .token-item {
      padding: 9px 14px 25px 7px;
      border-radius: 16px;

      &.dark-theme {
        background-color: #1d1933;
      }

      &.light-theme {
        background-color: #ffffff;
      }

      .title {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;

        color: #9e9e9e;
        margin-bottom: 14px;
        background-color: unset;
      }

      .balance-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        align-items: flex-start;
        gap: 1rem;

        @media (max-width: 360px) {
          margin-bottom: 15px;
        }

        .balance-token {
          display: flex;
          align-items: center;
          &-icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          &-currency {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-right: 15px;
            margin-bottom: 0;

            &.light-theme {
              color: #000;
            }
          }
          &-amount {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;

            &.light-theme {
              color: #000;
            }
          }
        }
        .balance-crowns {
          p {
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;

            @media (max-width: 360px) {
              font-size: 18px;
              line-height: 26px;
            }
          }
          p:first-child {
            margin-bottom: 10px;
            @media (max-width: 360px) {
              margin-bottom: 2px;
            }
          }
          p:last-child {
            margin-bottom: 0;
          }
        }
      }
      .token-buttons {
        display: flex;
        justify-content: center;
        .token-button {
          &:first-child {
            margin-right: 14px;
          }
          width: 160px;
        }
      }
    }
  }
}

.account-page-mobile-history {
  position: relative;
  z-index: 1;
  border-radius: 16px;
  padding: 17px 15px 23px 20px;
  margin-bottom: 24px;

  @media (max-width: 480px) {
    padding: 12px 5px 23px 10px;
  }

  &.dark-theme {
    background: #1d1933;
  }

  &.light-theme {
    background: #ffffff;
  }

  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #d9d9d9;
    position: relative;
    border-radius: 10px;
    width: 148px;
    padding: 6px 37px 7px 39px;
    background-color: #1d1933;
    margin-bottom: 18px;

    &.dark-theme {
      background: #1d1933;
    }

    &.light-theme {
      background: #ffffff;
      color: #1d1933;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: -1px;
      z-index: -1;
      border-radius: inherit;
      background-image: linear-gradient(
        33deg,
        #6955e7 30.37%,
        rgba(252, 186, 126, 0.8) 84.77%
      );
    }
  }

  .table-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;

    div {
      width: calc(50% - 6.5px);
      background-color: unset;

      &:first-child {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        @media (max-width: 360px) {
          font-size: 14px;
          line-height: 20px;
        }
        i {
          margin-right: 3px;
        }

        &.light-theme {
          color: #1d1933;
        }
      }

      &:nth-child(2),
      &:nth-child(4) {
        text-align: right;
      }

      &:nth-child(2),
      &:nth-child(3) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.light-theme {
          color: #1d1933;
        }

        @media (max-width: 400px) {
          font-size: 13px;
          line-height: 20px;
        }
      }
      &:nth-child(4) {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: flex-end;

        &.light-theme {
          color: #1d1933;
        }

        @media (max-width: 420px) {
          font-size: 12px;
          line-height: 20px;
          white-space: nowrap;
        }

        img {
          width: 26px;
          height: 26px;
          margin-right: 8px;

          @media (max-width: 420px) {
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }
        }

        p {
          margin-bottom: 0;
        }
      }
      &:nth-child(5) {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        white-space: nowrap;
        color: #6955e7;
        @media (max-width: 360px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .rewards-mobile-table {
    display: flex;
    flex-direction: column;
    gap: to-rem(35);
  }

  .reward-mobile-table-cell {
    display: flex;
    flex-direction: column;
    gap: to-rem(15);
    padding: 0 to-rem(10);
  }

  .rewards-mobile-table-cell__additional-info {
    display: flex;
    flex-direction: column;
  }

  .rewards-mobile-table-cell__additional-info-wrapper {
    display: flex;
    justify-content: space-between;
    gap: to-rem(20);
    padding: 0 to-rem(10);

    & > * {
      line-height: 120%;
    }

    & > *:first-child {
      font-weight: 600;
    }
  }

  .loadmore-button {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ddcdf1;
    cursor: pointer;
    background-color: unset;

    &.light-theme {
      color: #614b7d;
    }
  }
}

.sft-wrapper {
  display: flex;
  flex: 0 0 50%;
  flex-wrap: wrap;
  padding-bottom: 25px;
  gap: 9px;

  .sft-item {
    width: calc(50% - 4.5px);
    background: #2e1849;
    border-radius: 10px;
    padding: 13px 10px;

    &.light-theme {
      background-color: #ffffff;
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      background-color: unset;

      color: #d9d9d9;
      margin-bottom: 11px;
      text-align: center;

      &.light-theme {
        color: #1d1933;
      }
    }

    .picture {
      width: 100%;
      background: #30416d;
      border-radius: 10px;
      height: 112px;
      margin-bottom: 11px;
      object-fit: contain;
    }

    .content {
      justify-content: space-between;
      background-color: unset;

      @media (max-width: 440px) {
        flex-direction: column;
      }

      &.light-theme {
        p:first-child {
          color: #1d1933;
        }
      }

      p:first-child {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;

        @media (max-width: 440px) {
          margin-bottom: 2px;
        }
      }
      p:last-child {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #706d6d;

        @media (max-width: 440px) {
          font-size: 14px;
        }
      }
    }
    .withdraw-btn {
      background: linear-gradient(136.22deg, #a578df 24.47%, #4975e6 77.79%);
      border-radius: 10px;
      width: 146px;
      height: 31px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin: 0 auto;

      @media (max-width: 360px) {
        width: 100%;
      }
    }
  }
}
