@import "colors";

:root,
:before,
:after {
  // Colors
  --white-color: #{$col-total-white};
  --primary-modal-bg: #{$col-total-white};
  --primary-modal-border: #{$total-grey};
  --primary-card-bg: rgba(#{$total-grey-rgb}, 5%);
  --primary-card-bg-dark: rgba(#{$col-total-white-rgb}, 3%);
  --primary-card-bg-hover: rgba(#{$col-total-white-rgb}, 10%);
  --primary-text-light: #212529;
  --primary-text-dark: #6f4ef2;
  --primary-color: #{$theme-main-color};
  --invalid-color: #{$col-track-and-field};
  --theme-main-color: #{$theme-main-color};
  --primary-divider-color: #{$col-darkest-navy};
  --item-blue-bg: #{$blue-clock};

  // App Modal
  --app-modal-overlay-bg: rgba(#{$col-tristesse-rgb}, 70%);
  --app-modal-content-bg: #{$col-kon};
  --app-modal-close-btn-col: #{$col-darkest-navy};
  --app-modal-lend-btn-bg: #{$col-blue-deep};
  --app-modal-lend-btn-bg-hover: #{$col-blue-deep-hover};
  --app-modal-dark-btn-hover: rgba(#{$blue-dark-2}, 10%);
  --app-modal-light-btn-hover: #dbdbdb;

  // App Button
  --app-button-secondary-bg: var(--primary-card-bg-hover);

  // App Tabs
  --app-tabs-active-col: #{$col-purple-illusionist};

  // Base
  --primary-title-color: var(--white-color);
  --container-side-padding: #{to-rem(16)};
  --secondary-title-color: rgba(#{$col-total-white-rgb}, 80%);
  --primary-btn-color: var(--white-color);
  --secondary-color: #{$col-purple-illusionist};

  // Card
  --card-footer-hover-bg: #{$grey-dark};

  // Common
  --qr-code-bg: #{$col-white};

  // Transitions
  --fast-transition-duration: 0.3s;
  --hover-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --slide-transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  --hover-transition: var(--fast-transition-duration)
    var(--hover-transition-timing-function);
  --slide-transition: var(--fast-transition-duration)
    var(--slide-transition-timing-function);

  // Select Field
  --select-field-border-col: var(--primary-color);
  --select-field-bg-filled: #{$col-kon};
  --select-field-menu-option-bg-hover: #{$col-royal-curtsy};
  --select-field-menu-bg: #{$col-kon};

  // Input-fields
  --input-field-bg: #{$col-kon};

  // Z-indexes
  --z-app-modal-overlay: 20;
  --z-text-field-inner: 1;
  --z-text-field-hint: 0;
  --z-dropdown-menu: 25;
}
