.center-loader {
  width: 100%;
}

.lending {
  &-wrapper {
    padding-bottom: 20px;
  }
  &-title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 12px;

    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 36px;
    }
  }
  &-description {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 47px;
    width: 90%;
    &-link {
      color: #c4a0f2;
      font-weight: 700;
      font-family: "Poppins";
    }

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 26px;
    }
  }
  &-game-items {
    display: flex;
    flex: 0 0 50%;
    gap: 20px;
  }
  &-game-item {
    width: 50%;
    padding: 38px 44px 30px 44px;
    background: #1d1933;
    border-radius: 16px;

    &-image {
      width: 100%;
      min-width: 100%;
      height: 280px;
      min-height: 280px;
      margin-bottom: 10px;
      object-fit: cover;
      border-radius: 10px;
      background-color: gray;
    }

    @media (max-width: 769px) {
      padding: 21px 20px 30px 20px;

      &-image {
        min-height: 157px;
        height: 157px;
      }
    }

    @media (max-width: 900px) {
      width: 100%;
    }

    &-info {
      display: flex;
      flex: 0 0 33.33%;

      @media (max-width: "1100px") {
        flex-wrap: wrap;
      }

      &-reward-token-block {
        display: flex;
        gap: 3px;
        align-items: center;
        &-icon {
          width: 23px;
          height: 23px;

          @media (max-width: 769px) {
            width: 17px;
            height: 17px;
          }

          &.crt {
            background: url("/crt-logo.svg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        &-text {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #ddcdf1;
          margin-bottom: 0;

          @media (max-width: 769px) {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      &-item {
        width: 100%;
        background: #18142f;
        box-shadow: inset 1px 1px 4px #614b7d, inset -1px -1px 4px #614b7d;
        border-radius: 10px;
        padding: 21px 25px;

        &:first-child,
        &:nth-child(2) {
          margin-right: 26px;
        }

        @media (max-width: 769px) {
          padding: 23px 17px;
        }

        @media (max-width: "1100px") {
          &:first-child {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 26px;
            margin-right: 0;
          }

          &:not(:first-child) {
            width: calc(50% - 13px);
          }
        }

        &-title {
          margin-bottom: 20px;
          font-size: 16px;
          line-height: 19px;
          color: #f2f2f2;

          @media (max-width: 769px) {
            margin-bottom: 7px;
          }
        }

        &:first-child {
          .lending-game-item-info-item-title {
            @media (max-width: "1100px") {
              margin-bottom: 0;
            }
          }
        }

        &-value {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #ddcdf1;
          margin-bottom: 0;
        }
      }
    }
  }
}

// THEME
.lending {
  &-wrapper.dart-theme {
    .lending-title {
      color: #f5f5f5;
    }
    .lending-description {
      color: #ddcdf1;
    }
  }
  &-wrapper.light-theme {
    .lending-title {
      color: #000;
    }
    .lending-description {
      color: rgba(73, 56, 95, 0.72);
      &-link {
        color: rgba(73, 56, 95, 0.72);
      }
    }
    .lending-game-item {
      background: #ffffff;
    }
    .lending-game-item-info-item {
      background: #fffefe;
      box-shadow: inset 1px 1px 4px rgba(217, 217, 217, 0.61), inset -1px -1px 4px #ddcdf1;
      &-title {
        color: #614b7d;
      }
      &-value,
      .lending-game-item-info-reward-token-block-text {
        color: #444040;
      }
    }
  }
}
