.lending-details {
  &-wrapper {
    width: 100%;
    overflow: hidden;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 33px;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 17px;
      align-items: flex-start;
    }
  }
  &-title {
    font-size: 36px;
    line-height: 54px;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 13px;
    }
  }
  &-game-name {
    font-size: 24px;
    border-radius: 10px;
    padding: 5px 28px;
    position: relative;
    height: 41px;
    font-weight: 500;
    margin: 1px;

    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 30px;
      width: 210px;
      margin-left: auto;
      white-space: nowrap;
    }

    &.light-theme {
      background-color: $light;
      color: #614b7d;
    }

    &.dark-theme {
      background-color: #131129;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: -1px;
      z-index: -1;
      border-radius: inherit;
      background-image: linear-gradient(
        33deg,
        #6955e7 30.37%,
        rgba(252, 186, 126, 0.8) 84.77%
      );
    }
  }

  &-info {
    display: flex;
    gap: 20px;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  &-rewards-collections-column {
    min-width: 425px;

    @media (max-width: 1200px) {
      min-width: 100%;
      display: flex;
      gap: 15px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &-rewards {
    width: 100%;
    border-radius: 16px;
    padding: 27px 16px;
    margin-bottom: 20px;

    @media (max-width: 1200px) {
      margin-bottom: 0;
    }

    @media (max-width: 480px) {
      padding: 17px 14px;
      margin-bottom: 23px;
    }

    &.light-theme {
      background: #fff;
    }

    &.dark-theme {
      background: #1d1933;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        background: none;

        &.light-theme {
          color: #7e7e7e;
        }
      }

      &-title,
      &-value {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;

        @media (max-width: 480px) {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px;
        }
      }
    }

    &-icon {
      width: 25px;
      height: 25px;
      margin-right: 7px;
      vertical-align: middle;
      display: inline-block;

      @media (max-width: 480px) {
        margin-right: 0;
      }

      &.crt {
        background: url("/crt-logo.svg");
        background-position: center;
        background-size: cover;
      }
    }
  }

  &-collections {
    border-radius: 16px;
    max-height: 173px;
    padding: 13px 13px 30px 13px;
    position: relative;
    z-index: 0;

    @media (max-width: 1200px) {
      width: 100%;
      max-height: none;
    }

    &.dark-theme {
      background: #1d1933;
    }

    &.light-theme {
      background-color: #ffffff;
    }

    &-title {
      font-size: 24px;
      line-height: 36px;
      border-radius: 10px;
      margin-bottom: 30px;
      width: 173px;
      text-align: center;
      position: relative;

      &.dark-theme {
        background: #1d1933;
      }

      &.light-theme {
        background-color: #ffffff;
      }

      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        border-radius: inherit;
        margin: -1px;
        background-image: linear-gradient(
          33deg,
          #6955e7 30.37%,
          rgba(252, 186, 126, 0.8) 84.77%
        );
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    &-item {
      display: flex;
      margin-bottom: 7px;
      align-items: center;

      &-image {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 9px;
        background-color: gray;
      }
      &-name,
      &-token {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;

        @media (max-width: 480px) {
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: 280px) {
          font-size: 11px;
        }
      }
      &-name {
        margin-right: 5px;
      }
      &-token {
        margin-left: 5px;
      }
    }
  }

  &-history-column {
    border-radius: 16px;
    width: 100%;
    padding: 16px 29px 26px 29px;
    position: relative;
    z-index: 1;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    @media (max-width: 480px) {
      padding: 16px;
    }

    &.dark-theme {
      background: #1d1933;
    }
    &.light-theme {
      background: #ffffff;
    }

    @media (max-width: 767px) {
      &-title {
        font-size: 20px;
        line-height: 30px;
        position: relative;
        width: 148px;
        text-align: center;
        margin-bottom: 17px;
        border-radius: 10px;

        &.dark-theme {
          background: #1d1933;
        }

        &.light-theme {
          background-color: #ffffff;
        }

        &::after {
          content: "";
          position: absolute;
          z-index: -1;
          border-radius: inherit;
          margin: -1px;
          background-image: linear-gradient(
            33deg,
            #6955e7 30.37%,
            rgba(252, 186, 126, 0.8) 84.77%
          );
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  &-history-mobile-table-item {
    display: flex;
    flex: 0 0 50%;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 30px;
    div {
      width: 50%;
      text-align: center;
    }
    &-token,
    &-crt {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      @media (max-width: 360px) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 7px;
      }

      &-icon {
        width: 25px;
        height: 25px;
        margin-right: 7px;
        vertical-align: middle;
        display: inline-block;

        &.crt {
          background: url("/crt-logo.svg");
          background-position: center;
          background-size: cover;
        }
      }
    }
    &-date {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      @media (max-width: 360px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &-history-table {
    width: 100%;
    border-spacing: 0;

    &-header {
      border-bottom: 40px solid transparent;
    }
    &-body {
      overflow: hidden;
      max-height: 262px;
      display: block;
      &-item {
        border-bottom: 20px solid transparent;
      }
      &::-webkit-scrollbar {
        width: 1px;
      }
      &::-webkit-scrollbar-track-piece {
        background: linear-gradient(
          270deg,
          rgba(183, 88, 241, 0.54) 32.69%,
          rgba(255, 255, 255, 0.54) 66.15%
        );
        width: 1px;
      }
      &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 16px;
        background: linear-gradient(180deg, #b758f1 0%, #ffffff 100%);
        border-radius: 10px;
      }
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    tr {
      td {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        &.light-theme {
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }

  &-nfts {
    display: flex;
    gap: 20px;
    margin-top: 18px;
    width: 100%;
    justify-content: center;
    align-items: center;

    > .infinite-scroll-component__outerdiv {
      width: 100%;
    }

    @media (max-width: 767px) {
      width: 120%;
    }

    @media (max-width: 480px) {
      width: 164%;
    }
  }

  &-loader-wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: -5px;
  }
}

.history-table-body-item-icon {
  width: 26px;
  height: 26px;
  vertical-align: middle;
  margin-right: 8px;
  display: inline-block;
  &.crt {
    background: url("/crt-logo.svg");
    background-position: center;
    background-size: cover;
  }
}

.history-table-body-item-text {
  display: inline-block;
  vertical-align: middle;
}
.lending-details-nfts-wrapper {
  &::-webkit-scrollbar {
    display: none;
  }
}

.thumb-horizontal {
  height: 10px;
  bottom: -4px;
  background: #b758f1;
  border-radius: 10px;
  position: absolute;
}
.track-horizontal {
  background: #b758f1;
  width: 100%;
  height: 1px;
  position: relative;
  top: calc(100% - 2px);
  margin-bottom: 5px;
}

.scroll-line {
  background: linear-gradient(
    270deg,
    rgba(183, 88, 241, 0.54) 32.69%,
    rgba(255, 255, 255, 0.54) 66.15%
  );
  width: 1px;
  height: 100%;
  position: relative;
  left: calc(100% - 3px);
}

.scroll-thumb {
  width: 5px;
  left: -2px;
  background: linear-gradient(180deg, #b758f1 0%, #ffffff 100%);
  border-radius: 10px;
  position: absolute;
}

.staking-slider {
  display: flex;
  overflow: auto;
}
