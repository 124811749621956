.form-label {
  font-weight: $headings-font-weight;
}

.form-control {
  border-radius: to-rem(10);
  height: 45px;
  border: 1px solid $border-color;
  padding: 0px 22px;
  font-size: 16px;
  // font-weight  : 500;
  color: $dark;
  // transition   : all 0.3s ease-in-out;
  background: $white;

  .nickname-input {
    color: $dark;
    background: transparent;
  }

  &.nickname {
    background: $body-bg;
  }

  .nickname-button {
    color: $dark;
  }

  span {
    margin-top: 0;
  }

  &::-webkit-input-placeholder {
    color: $body-color;
  }

  &:-ms-input-placeholder {
    color: $body-color;
  }

  &::placeholder {
    color: $body-color;
  }

  &:hover {
    box-shadow: none !important;
    outline: none;
    border: 0.0625rem solid var(--theme-main-color);
    color: $dark;
    background: $white;
  }

  &:focus,
  &:active,
  &.active {
    box-shadow: none !important;
    outline: none;
    border-color: $primary;
    color: $dark;
    background: $white;
  }
}

.dark-theme .send-modal-field {
  //background-color: var(--app-modal-content-bg);

  //&:hover{
  //    background-color: var(--app-modal-content-bg);
  //}

  &::placeholder {
    color: var(--primary-modal-border);
  }
}

.dark-theme .form-control {
  border: 0.0625rem solid var(--theme-main-color);

  &.nickname {
    background: inherit;
  }

  .nickname-input {
    color: $white;
  }

  .nickname-button {
    color: $white;
  }
}

.is-invalid {
  border-color: $danger !important;

  //&:hover{
  //    border: to-rem(1) solid var(--theme-main-color) !important;
  //}
}

.error-input {
  color: $danger;
  font-size: small;
}

.error-placeholder {
  color: var(--primary-modal-border) !important;
}

.form-select {
  border-radius: 5px;
  height: 45px;
  border: 1px solid $border-color;
  padding: 0px 22px;
  font-size: 14px;
  // font-weight  : 500;
  color: $dark;
  // transition   : all 0.3s ease-in-out;

  &:hover {
    box-shadow: none !important;
    outline: none;
    border-color: $border-color;
    color: $dark;
  }

  &:focus,
  &:active,
  &.active {
    box-shadow: none !important;
    outline: none;
    border-color: $primary;
    color: $dark;
  }
}

textarea.form-control {
  min-height: 100px;
}

input:-internal-autofill-selected {
  background: lighten($body-bg, 2%) !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
  padding: 11px 15px;
  background: $white;
  margin-bottom: 0px !important;
  color: $dark;
  border-color: $border-color;
  border-radius: 0px;
}

.input-group-append {
  .input-group-text {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.input-group-prepend {
  .input-group-text {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

// Validation Error
label.error {
  color: $danger;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400;
}

.addressTooltip {
  position: relative;
  display: inline-block;
  //border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.addressTooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  bottom: 100%;
  left: 50%;
  margin-left: -60px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 2;

  &.nft-footer {
    margin-left: -185px;
  }
}

.addressTooltip:hover .tooltiptext {
  visibility: visible;
}
