.optionsTitle {
  h3 {
    font-size: to-rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  p {
    font-size: to-rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  @media (max-width: to-rem(575)) {
    h3 {
      font-size: to-rem(16);
    }

    p {
      font-size: to-rem(12);
    }
  }
}

.optionButtonsWrapper {
  display: flex;
  align-items: center;
  padding-top: to-rem(32);
  gap: to-rem(24);

  @media (max-width: to-rem(660)) {
    width: 90%;
    justify-content: center;
    gap: to-rem(5);
  }
}

.optionButton {
  width: 20vw;
  height: to-rem(96);
  border-width: to-rem(0);
  background-color: var(--primary-modal-border);
  border-radius: to-rem(16);
  color: var(--white-color);
  cursor: pointer;
  font-size: to-rem(24);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &:hover {
    background-color: var(--app-modal-lend-btn-bg-hover);
  }

  &.selected {
    background-color: var(--app-modal-lend-btn-bg-hover);
  }

  @media (max-width: to-rem(660)) {
    width: 100%;
    min-width: to-rem(110);
    height: to-rem(60);
    font-size: to-rem(18);
    white-space: nowrap;
  }
}

.dark-theme .optionButton {
  background-color: var(--app-modal-lend-btn-bg);
  color: var(--white-color);

  &:hover {
    background-color: var(--app-modal-dark-btn-hover);
  }

  &.selected {
    background-color: var(--app-modal-dark-btn-hover);
  }
}

.optionConfirm {
  h3 {
    font-size: to-rem(18);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: to-rem(8);
  }
  p {
    font-size: to-rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: to-rem(16);
  }
}

.dataEntryWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: to-rem(32);
  gap: to-rem(16);
}

.datePicker {
  width: 100%;
  height: to-rem(42);
  border-radius: to-rem(10);
  //max-width: to-rem(150);
  cursor: pointer;
}

.text-field-label {
  padding-bottom: to-rem(0.1);
  font-size: to-rem(14);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 100%;

  @media (max-width: #{to-rem(767)}) {
    margin-bottom: to-rem(15);
  }
}

.switch-toggle {
  position: absolute;
  margin-left: to-rem(160);

  @media (max-width: #{to-rem(767)}) {
    margin-left: to-rem(0);
  }
}

.switch-label {
  @media (max-width: #{to-rem(767)}) {
    position: absolute;
    margin-bottom: to-rem(20);
    margin-left: to-rem(50);
  }
}

.timePicker {
  height: to-rem(42);
  cursor: pointer;
}

.dateWrapper {
  display: flex;
  flex-direction: row;
  gap: to-rem(4);
  //justify-content: center;
}

.timeIconWrapper {
  display: flex;
  width: to-rem(42);
  min-width: to-rem(42);
  height: to-rem(42);
  align-items: center;
  justify-content: center;
  background: var(--item-blue-bg);
  border-radius: to-rem(8);
  transition: background-color 0.15s ease;

  &--disabled {
    background: var(--primary-modal-border);
  }
}

.timeIcon {
  color: var(--white-color);
}

input.time-input {
  min-width: to-rem(100);
  max-width: to-rem(110);

  @media (max-width: #{to-rem(900)}) {
    width: auto;
  }
}

input.date-input {
  min-width: to-rem(150);
  max-width: to-rem(160);

  &--disabled {
    border-color: var(--primary-modal-border) !important;
    color: var(--primary-modal-border) !important;

    &:hover {
      border-color: var(--primary-modal-border) !important;
    }
  }

  @media (max-width: #{to-rem(900)}) {
    width: auto;
  }
}
