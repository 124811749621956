// html,
// body,
// div,
// span,
// applet,
// object,
// iframe,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// blockquote,
// pre,
// a,
// abbr,
// acronym,
// address,
// big,
// cite,
// code,
// del,
// dfn,
// em,
// img,
// ins,
// kbd,
// q,
// s,
// samp,
// small,
// strike,
// strong,
// sub,
// sup,
// tt,
// var,
// b,
// u,
// i,
// center,
// dl,
// dt,
// dd,
// ol,
// ul,
// li,
// fieldset,
// form,
// label,
// legend,
// table,
// caption,
// tbody,
// tfoot,
// thead,
// tr,
// th,
// td,
// article,
// aside,
// canvas,
// details,
// embed,
// figure,
// figcaption,
// footer,
// header,
// hgroup,
// menu,
// nav,
// output,
// ruby,
// section,
// summary,
// time,
// mark,
// audio,
// video {
//   margin: 0;
//   padding: 0;
//   border: 0;
//   font-size: 100%;
//   vertical-align: baseline;
// }
// /* HTML5 display-role reset for older browsers */
// article,
// aside,
// details,
// figcaption,
// figure,
// footer,
// header,
// hgroup,
// menu,
// nav,
// section {
//   display: block;
// }
// body {
//   line-height: 1;
// }
// ol,
// ul {
//   list-style: none;
// }
// blockquote,
// q {
//   quotes: none;
// }
// blockquote:before,
// blockquote:after,
// q:before,
// q:after {
//   content: "";
//   content: none;
// }
// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }

// @import "../vendor/animate/animate.min.css";
// @import "../vendor/waves/waves.min.css";
// @import "../vendor/perfect-scrollbar/perfect-scrollbar.css";
// @import "../vendor/basic-table/basictable.css";

* {
  outline: none;
  padding: 0;

  &::after {
    margin: 0;
    padding: 0;
  }

  &::before {
    margin: 0;
    padding: 0;
  }
}

#main-wrapper {
  // opacity      : 0;
  transition: all 0.25s ease-in;
  // overflow  : hidden;
  position: relative;
  z-index: 1;
  // height: 100vh;
  margin-top: 110px;

  // &::before {
  //     content   : "";
  //     height    : 300px;
  //     top       : 0;
  //     left      : 0;
  //     width     : 100%;
  //     position  : absolute;
  //     z-index   : -9;
  //     background: $primary;
  // }

  &.show {
    opacity: 1;
  }
}

.content-body {
  margin-left: $sidebar-width;
  // margin-right       : $map-width +30px;
  // padding-top     : 100px;
  margin-top: 30px;
  margin-bottom: 50px;

  @include media-breakpoint-up(sm) {
    margin-left: 0px;
    margin-bottom: 50px;
  }
}

.details {
  .content-body {
    margin-left: 0px;
  }
}

.dashboard {
  .content-body {
    margin-left: $sidebar-width;
    margin-right: 0px;
    margin-top: 0px;

    // @include media-breakpoint-up(sm) {
    //     margin-left  : 0px;
    //     margin-bottom: 50px;
    // }

    // @include respond("md") {
    //     margin-left: 100px;
    // }

    @include respond('sm') {
      margin-left: 0px;
    }
  }
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: $primary;
  text-decoration: none;
  outline: none;

  &:hover,
  &:focus,
  &.active {
    text-decoration: none;
    outline: none;
    color: $primary;
  }
}

b,
strong {
  color: $headings-color;
}
