.total-balance {
    p {
        margin-bottom: 0px;
    }
}

.balance-stats {
    background      : #F5F6FA;
    margin-top      : 15px;
    // border       : 1px solid $border-color;
    padding         : 21px;
    border-radius   : $radius;

    &:hover,
    &:focus,
    &.active {

        border-color: $primary;
        background  : $primary;

        p {
            color  : $white;
            opacity: 0.75;
        }

        h3 {
            color: $white;
        }
    }

    p {
        color        : $body-color;
        font-size    : 14px;
        font-weight  : 400;
        margin-bottom: 0px;
    }

    h3 {
        margin-bottom: 0px;
        font-size    : 18px;
    }
}

.bills-widget-content {
    border       : 1px solid $border-color;
    padding      : 15px 20px;
    border-radius: $radius;
    margin-bottom: 18px;

    &:hover,
    &:focus,
    &.active {
        border-color: $primary;
    }

    &:last-child {
        margin-bottom: 0px;
    }


    p {
        margin-bottom: 5px;
    }

    h4 {
        margin-bottom: 0px;
    }
}

#transaction-graph {
    height: 300px !important;
}