.error-modal {
  border: 1px solid #dc3545 !important;
  max-width: 530px;

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      color: #dc3545 !important;
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    &__message {
      font-size: 16px;
      margin-bottom: 10px;
      word-break: break-word;
    }

    &__btn {
      margin-top: 10px;
    }
  }
}

.dark-theme {
  .modal-header {
    .btn {
      color: #ffffff !important;
    }
  }
}

.modal-header {
  .btn {
    color: #000000 !important;
  }
}
