button,
.btn {
  color: #fff;
  padding: 8px 30px;
  // line-height: 0px;
  font-weight: 700;
  //display    : inline-block;
  border-radius: 12px;

  &:hover,
  &:focus,
  &:active {
    // background: $white;
    color: #fff;
  }
}

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success,
.btn-outline-info,
.btn-outline-warning,
.btn-outline-danger,
.btn-outline-light {
  color: $dark;

  &:hover,
  &:focus,
  &:active {
    color: $white;
  }
}

.btn-outline-dark {
  color: $dark;

  &:hover,
  &:focus,
  &:active {
    color: $white;
  }
}

.btn-custom-outline {
  border: to-rem(1) solid var(--primary-modal-border);

  &:hover {
    border: to-rem(1) solid var(--primary-modal-border);
    background-color: var(--primary-modal-border);
    color: var(--primary-modal-bg);
  }
}

input::file-selector-button {
  padding: 0.5em;
  border: to-rem(1) solid var(--primary-text-dark);
  background: transparent;
  border-radius: 12px;
  color: inherit;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    //border: to-rem(1) solid var(--secondary-color);
    //color: var(--secondary-color);
    color: var(--white-color);
    background-color: var(--primary-text-dark);
  }
}

.dark-theme input::file-selector-button {
  color: var(--white);
}

.dark-theme .btn-custom-outline {
  border: to-rem(1) solid var(--theme-main-color);

  &:hover {
    border: to-rem(1) solid var(--theme-main-color);
    background-color: var(--theme-main-color);
    color: var(--primary-modal-bg);
  }
}

.back-btn {
  color: var(--primary-modal-border);

  &:hover {
    color: var(--primary-color);
  }
}

.dark-theme .back-btn {
  color: var(--theme-main-color);

  &:hover {
    color: var(--primary-color);
  }
}

.tw-button {
  @apply rounded-xl px-[30px] py-2 font-semibold;

  &.tw-button--primary {
    @apply bg-primary text-white;
  }

  &.tw-button--secondary {
    @apply bg-secondary text-white;
  }
}

.btn-no-border {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--primary-modal-border);

  &:hover,
  &:focus,
  &:active {
    color: var(--theme-main-color);
    background-color: transparent;
  }
}
