.footer {
    // border-top: 1px solid rgba(255, 255, 255, 0.2);
    background: $body-bg;
    padding   : 20px 0px;

    position   : fixed;
    bottom     : 0;
    left       : $sidebar-width + 30px;
    // right   : $map-width + 30px;

    border-radius: $radius;

    .copyright {
        position  : relative;
        text-align: left;

        p {
            margin   : 0;
            font-size: 14px;
            color    : $body-color;

            a {
                color      : $primary;
                font-weight: 700;
            }
        }
    }

    .footer-social {
        text-align: right;

        li {
            display: inline-block;

            a {
                color  : $dark;
                padding: 0px 10px;
            }
        }
    }
}

.details {
    .footer {
        left: 0px;
    }
}