// .bg-gradient-primary {
//     background: linear-gradient(50deg, #6e00ff 0, #bb00ff 100%) !important;
// }




.bg-purple {
    background-color: $purple !important
}


.bg-transparent {
    background-color: transparent !important
}