.stat-widget {
  background: #fff;
  padding: 20px;
  border-radius: $radius;
  margin-bottom: 30px;
  position: relative;
  box-shadow: $shadow;

  .widget-icon {
    // background     : rgba(0, 0, 0, 0.15);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 20px;
    color: $white;

    i {
      line-height: 0px;
    }
  }

  .widget-content {
    // text-align: right;

    p {
      color: $body-color;
      opacity: 0.85;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0px;
    }

    h3 {
      // color: $dark;
      font-size: 20px;
      margin-bottom: 0px;
    }

    // p {
    //     // background   : $dark;
    //     display      : inline-block;
    //     padding      : 3px 7px;
    //     border-radius: $radius - 16px;
    // }
  }

  .widget-arrow {
    position: absolute;
    right: 26px;
    font-weight: 500;

    i {
      position: relative;
      top: -3px;
      margin-left: 5px;
    }
  }
}

.items {
  .creator {
    position: absolute;
    bottom: -5px;
    right: 20px;
    border-radius: 100px;
  }

  .btn {
    min-width: 100%;
    margin: 5px 5px;
    // font-size: 13px;
    padding: 12px;
  }
}

.filter-nav {
  a {
    display: inline-block;
    padding: 3px 20px;
    margin-left: 10px;
    border-radius: $radius;
    color: $dark;

    // min-width  : 110px;
    &.active {
      background: $primary;
      color: $white;
    }
  }
}

// activity-content

.activity-content {
  // margin: 0px -15px;

  li {
    display: block;
    position: relative;
    border-bottom: 1px solid $border-color;
    padding: 20px 30px;

    &:last-child {
      // margin-bottom: 0px;
      border: 0px;
    }
  }

  .activity-user-img {
    img {
      border-radius: $radius + 50px;
    }
  }

  .activity-info {
    position: relative;
    display: block;

    p {
      margin-bottom: 5px;
    }
  }
}

#most-selling-items {
  height: 280px !important;
}

#activity {
  height: 280px !important;
}

#user-activity {
  .btn {
    padding: 5px 15px;

    @include respond("sm") {
      margin-top: 10px;
    }
  }
}

.shown {
  &.promotion {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #000;
      opacity: 0.6;
      z-index: 1;
    }
  }
}

.promotion2 {
  padding: 20px 30px;
  border-radius: $radius;
  margin-bottom: 30px;
  box-shadow: $shadow;
  // background: url("/images/CR-back.jpg");
  // background-size: cover;
  // background-position: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  min-height: 400px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0;
    z-index: -1;
    transition: opacity 200ms;
  }

  .promotion-detail {
    position: relative;
  }
}

.promotion {
  padding: 20px 30px;
  border-radius: $radius;
  margin-bottom: 30px;
  box-shadow: $shadow;
  // background: url("/images/cover-cantina.jpeg");
  // background-size: cover;
  // background-position: center;
  position: relative;
  overflow: hidden;
  min-height: 400px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0;
    z-index: -1;
    transition: opacity 200ms;
  }

  .promotion-detail {
    position: relative;
    z-index: 2;
    // max-width: 800px;
    // padding  : 20px 0px;

    h3 {
      font-size: 26px;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 30px;
    }

    .btn {
      padding: 12px 30px;
      min-width: 150px;
      margin: 10px 0px;
    }
  }
}

.top-bid {
  @include respond("sm") {
    .rounded {
      margin-bottom: 30px;
    }
  }

  .avatar-img {
    border-radius: 100px;
    max-width: 30px;
  }

  h6 {
    font-size: 14px;
  }

  .circle {
    width: 8px;
    height: 8px;
    display: inline-block;
    // background: $primary;
    border-radius: 100px;
    margin-left: 10px;
  }

  .btn {
    min-width: 45%;
    margin: 5px 5px;
    font-size: 14px;
    padding: 12px;
  }
}

.top-creators-content {
  .creator-widget {
    padding: 20px 30px;
    border-radius: $radius;
    margin-bottom: 20px;
    position: relative;
    box-shadow: $shadow;
    background: #fff;

    .top-creators-user-img {
      img {
        border-radius: $radius + 50px;
      }
    }

    .top-creators-info {
      position: relative;
      display: block;

      p {
        margin-bottom: 5px;
      }
    }

    .btn {
      padding: 8px;
      min-width: 120px;
      font-size: 14px;
    }
  }
}

.bid-table {
  table {
    border-collapse: separate;
    border-spacing: 0 10px;

    // th {
    //     border: 0px !important;
    // }

    td {
      background: lighten($white, 0%);
      border: 0px;
      vertical-align: middle;

      &:first-child {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
      }

      &:last-child {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
      }
    }

    tbody {
      tr {
        &.token {
          height: 80px;
        }
      }
    }
  }
}

.not-found-error-continer {
  padding: 30px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
