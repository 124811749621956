.nft-wrapper {
  .nft-card {
    background: var(--bg-theme-color);
    border-radius: 16px;
    padding: 45px 28px 31px 28px;
    min-width: 320px;
    margin-right: 20px;
    max-height: 574px;

    @media (max-width: 990px) {
      margin-right: 0;
      padding: 17px 32px 16px 32px;
      flex-direction: column;
      display: flex;
      align-items: center;
    }

    &-img {
      width: 264px;
      height: 370px;
      margin: 0 auto 23px auto;
      background-color: gray;

      @media (max-width: 667px) and (max-height: 375px) {
        object-fit: contain;
        background-color: transparent;
      }
    }

    .nft-title {
      margin-bottom: 18px;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: gray;
        margin-right: 9px;
      }
      h4 {
        font-weight: 500;
        font-size: 16px;
        color: var(--text-theme-color);
        white-space: nowrap;
        margin-bottom: 0;
        line-height: 24px;
      }
    }
    .nft-royalties {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-theme-color);
      text-align: center;
      margin-bottom: 5px;
    }
    .nft-token-id {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-theme-color);
      margin-bottom: 0px;
      text-align: center;
    }
  }

  .nft-staff {
    background: var(--bg-theme-color);
    border-radius: 16px;
    padding: to-rem(9) to-rem(19) to-rem(19) to-rem(31);
    width: 100%;

    &-wrapper {
      @media (max-width: 1280px) {
        flex-direction: column;
      }
    }

    @media (max-width: 900px) {
      padding: to-rem(0);
    }

    .nft-title-section {
      justify-content: space-between;
    }

    .nft-about-section {
      min-width: 351px;
      max-width: 50%;
      margin-right: 60px;

      @media (max-width: 900px) {
        min-width: 100%;
      }

      @media (max-width: 989px) {
        margin-right: 0;
        padding: 13px 0;
      }

      .nft-about-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-theme-color);
        margin-bottom: 10px;
        margin-top: 7px;
      }
      .nft-game-selector-section {
        &__selector {
          width: calc(100% - 105px);
          margin-right: 18px;
          label {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            color: rgba(183, 88, 241, 0.54);
          }

          .game-selector {
            width: 100%;
            color: var(--text-theme-color);
          }
        }
        &__game-lvl {
          width: 87px;

          input {
            text-align: center;
          }

          label {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            color: rgba(183, 88, 241, 0.54);
          }
        }
      }
      .nft-attributes-wrapper {
        margin-top: 30px;
        .nft-attributes-item {
          justify-content: space-between;
          margin-bottom: 10px;
          p {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: var(--text-theme-color);
          }
        }
      }
    }
    .nft-attributes {
      width: 100%;

      @media (max-width: 1280px) {
        margin-top: 30px;
      }

      &-title {
        margin-left: auto;
        margin-bottom: 9px;

        @media (max-width: 1280px) {
          margin-left: 0;
          margin-bottom: 15px;
        }
      }
      &-list {
        display: flex;
        flex-wrap: wrap;
        &-item {
          width: 50%;
          margin-bottom: 10px;
          h3 {
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #a578df;
            margin-bottom: 7px;
          }
          p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: var(--text-theme-color);
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .nft-table {
    display: flex;
    padding: 19px 20px 37px 27px;
    background: var(--bg-theme-color);
    border-radius: 16px;

    .nft-table-wrapper {
      width: calc(100% - 20px);

      thead tr {
        border-bottom: 30px solid transparent;
        td {
          font-weight: bold;
        }
      }

      tbody tr {
        border-bottom: 12px solid transparent;
      }
    }

    &-dropdown {
      margin-left: auto;
      &-selector {
        width: 235px;
      }
    }
  }
}

.nft-mobile-title {
  margin-left: auto;

  @media (max-width: 990px) {
    font-size: 16px !important;
  }
}

.crr-icon {
  width: 26px;
  height: 27px;
  border-radius: 50%;
  background-color: gray;
  margin-right: 9px;
  display: inline-block;
}

.history-block {
  background: var(--bg-theme-color);
  border-radius: 16px;
  padding: 12px 21px 31px 13px;
  &-table {
    padding-top: 20px;
    &-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .crr-icon {
        width: 26px;
        height: 27px;
        border-radius: 50%;
        background-color: gray;
        margin-right: 9px;
        display: inline-block;
      }

      div {
        width: 25%;
        margin-bottom: 13px;

        @media (max-width: 600px) {
          width: 50%;
        }

        p {
          margin-bottom: 0;
        }
      }

      @media (max-width: 600px) {
        div:nth-child(even) {
          text-align: right;
        }
      }

      .status {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: rgba(50, 116, 40, 0.72);
      }

      .ended {
        color: var(--card-footer-hover-bg);
      }
    }
  }
}

.green-status {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(50, 116, 40, 0.72);
}

.grey-status {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--card-footer-hover-bg);
}
