#preloader {
    position        : fixed;
    width           : 100%;
    height          : 100%;
    left            : 0;
    top             : 0;
    background-color: $white;
    z-index         : 999999999;


    i {
        display          : block;
        width            : 16px;
        height           : 16px;
        background       : black;
        border-radius    : 16px;
        position         : absolute;
        top              : 50%;
        left             : 50%;
        margin           : -8px 0 0 -8px;
        opacity          : 1;
        -webkit-transform: translate3d(60px, 0, 0);
        overflow         : hidden;
        text-indent      : -9999px;
        border           : 1px solid white;

        &:nth-child(1) {
            background       : $primary; // Red
            -webkit-animation: googleDotA 1.75s ease-in-out infinite;
        }

        &:nth-child(2) {
            background       : $success; // Yellow
            -webkit-animation: googleDotB 1.75s ease-in-out infinite;
        }

        &:nth-child(3) {
            background       : $warning; // Blue
            -webkit-animation: googleDotC 1.75s ease-in-out infinite;
        }
    }
}

@-webkit-keyframes googleDotA {
    0% {
        opacity          : 0;
        transform        : translate3d(60px, 0, 0);
        -webkit-transform: translate3d(60px, 0, 0);
    }

    30% {
        opacity          : 1;
        transform        : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
    }

    70% {
        opacity          : 1;
        transform        : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
    }

    100% {
        opacity          : 0;
        transform        : translate3d(-300px, 0, 0);
        -webkit-transform: translate3d(-300px, 0, 0);
    }
}

@-webkit-keyframes googleDotB {
    0% {
        opacity          : 0;
        transform        : translate3d(180px, 0, 0);
        -webkit-transform: translate3d(180px, 0, 0);
    }

    35% {
        opacity          : 1;
        transform        : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
    }

    77% {
        opacity          : 1;
        transform        : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
    }

    100% {
        opacity          : 0;
        transform        : translate3d(-180px, 0, 0);
        -webkit-transform: translate3d(-180px, 0, 0);
    }
}

@-webkit-keyframes googleDotC {
    0% {
        opacity          : 0;
        transform        : translate3d(300px, 0, 0);
        -webkit-transform: translate3d(300px, 0, 0);
    }

    40% {
        opacity          : 1;
        transform        : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
    }

    80% {
        opacity          : 1;
        transform        : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
    }

    100% {
        opacity          : 0;
        transform        : translate3d(-60px, 0, 0);
        -webkit-transform: translate3d(-60px, 0, 0);
    }
}



