// Attributes
@use "sass:math";

.token-metadata-tabs__attributes-list {
  display: grid;
  font-size: 14px;
  grid-gap: 8px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: #{to-rem(767)}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (max-width: #{to-rem(767)}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.token-metadata-tabs__attributes-item {
  padding: to-rem(12) to-rem(16) !important;
  background-color: var(--primary-card-bg);
  border-radius: 12px;
  cursor: default;
  word-break: break-word;
}

.dark-theme .token-metadata-tabs__attributes-item {
  background-color: var(--primary-card-bg-dark);
}

.token-metadata-tabs__attributes-trait {
  overflow: hidden;
  margin-bottom: to-rem(10);
  color: var(--primary-text-light);
  font-weight: 500;
  letter-spacing: 0.05em;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.dark-theme .token-metadata-tabs__attributes-trait {
  color: var(--primary-text-dark);
}

.token-metadata-tabs__attributes-value {
  // margin-bottom: to-rem(10);
  //color: var(--primary-text-light);
  font-size: to-rem(16);
  font-weight: 500;
}

// Stats
.token-metadata-tabs__stats-fields-wrapper {
  display: grid;
  margin-bottom: to-rem(22);
  grid-gap: to-rem(16);
  grid-template-columns: 1fr to-rem(90);
}

.token-metadata-tabs__stats-fields-label {
  margin-bottom: to-rem(8);
  font-size: to-rem(14);
}

.token-metadata-tabs__stats-game-level-input {
  font-size: to-rem(16);
}

.token-metadata-tabs__stats-game-level-input > .text-field__inner {
  border: 0.0625rem solid var(--primary-modal-border) !important;

  &:hover {
    border: 0.0625rem solid var(--primary-modal-border) !important;
  }
}

.dark-theme .token-metadata-tabs__stats-game-level-input > .text-field__inner {
  border: 0.0625rem solid var(--theme-main-color) !important;

  &:hover {
    border: 0.0625rem solid var(--theme-main-color) !important;
  }
}

.token-metadata-tabs__stats-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: to-rem(10) 0;
  border-bottom: to-rem(1) solid var(--primary-divider-color);

  &:not(:last-child) {
    margin-bottom: to-rem(6);
  }
}

.token-metadata-tabs__stats-item-value {
  //color: var(--secondary-title-color);
}

// Description
.token-metadata-tabs__collection-desc-wrapper {
  margin-bottom: to-rem(22);
  font-size: to-rem(14);

  @media (min-width: #{to-rem(900)}) {
    display: flex;
    align-items: flex-start;
    font-size: to-rem(16);
  }
}

.token-metadata-tabs__collection-desc-logo {
  width: to-rem(120) !important;
  border: transparent !important;
  margin-bottom: to-rem(12);
  border-radius: to-rem(0) !important;
  object-fit: contain;

  @media (min-width: #{to-rem(900)}) {
    margin-right: to-rem(25);
    margin-bottom: 0;
  }
}

.dark-theme .token-metadata-tabs__stats-item {
  color: var(--primary-title-color);
}
