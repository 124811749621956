@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.deposit-main {
  height: 100vh;
}

.logout-btn {
  width: 198px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.margin-top {
  margin-top: 2%;
}

.deposit-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.deposit-main-layout {
  padding-bottom: 2rem;
  width: 500px;

  @media (max-width: 500px) {
    width: 320px;
  }

  .main-title {
    text-align: center;
    padding-bottom: 2rem;

    h1 {
      font-size: 2.5rem;
      font-weight: 400;
    }
  }

  .inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .action {
    padding: 10px;

    .actionInput {
      max-width: 100%;
      padding: 8px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      outline: none;
      margin-top: 10px;
    }

    .actionInput:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }

    .actionInput:disabled {
      background-color: #f0f0f0;
      cursor: not-allowed;
    }

    h2 {
      margin: 0;
    }
  }

  .title {
    margin-bottom: 13px;

    .action {
      display: flex;
      justify-content: space-between;
      padding: 0.2rem 1rem;
      align-items: center;

      h2 {
        font-size: 1rem;
        font-weight: 500;
      }

      button {
        padding: 0 8px;
        font-size: 23px;
      }
    }
  }

  .token-box {
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow:
      1px 1px 6px rgba(217, 217, 217, 0.65),
      -1px -1px 6px rgba(217, 217, 217, 0.5);
    border-radius: 5px;
    padding: 23px 20px 20px 20px;
    box-sizing: border-box;
    margin-bottom: 23px;

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .asset-box {
      @media (max-width: 500px) {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }

    .token-available {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      margin-left: 41px;
      color: #858383;
    }

    .token-info {
      display: flex;
      align-items: center;
      width: 104px;
      justify-content: space-between;
      margin-left: auto;

      @media (max-width: 500px) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
      }

      &.big {
        width: 170px;

        .token-amount {
          width: 100px;
        }
      }

      &-minus {
        background: url(../../minus.svg);
        width: 18px;
        height: 18px;
        cursor: pointer;
      }

      &-plus {
        background: url(../../plus.svg);
        width: 18px;
        height: 18px;
        cursor: pointer;
      }

      .token-amount {
        background-color: transparent;
        outline: none;
        width: 44px;
        height: 38px;
        text-align: center;
        border: 0.5px solid #614b7d;
        border-radius: 5px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type='number'] {
          -moz-appearance: textfield;
        }
      }

      .token-name {
        margin-left: 9px;
      }

      h3 {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    .token-img {
      align-items: center;
      display: flex;
      border-radius: 50%;

      img {
        width: 30px;
        height: 30px;
      }

      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-left: 9px;
      }
    }
  }
}

.select-sft-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .save-sft-btn {
    width: 228px;
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 20px;
  }

  .sft-list {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    height: 280px;
    max-height: 400px;
    overflow-y: auto;

    .sft-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.5rem 1rem;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border-bottom: 1px solid #ddd;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .token-available {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-left: 41px;
        color: #858383;
      }

      .token-info {
        display: flex;
        align-items: center;
        width: 104px;
        justify-content: space-between;
        margin-left: auto;

        &-minus {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }

        &-plus {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }

        .token-amount {
          background-color: transparent;
          outline: none;
          width: 44px;
          height: 38px;
          text-align: center;
          border: 0.5px solid #614b7d;
          border-radius: 5px;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          &[type='number'] {
            -moz-appearance: textfield;
          }
        }

        .token-name {
          margin-left: 9px;
        }

        h3 {
          font-size: 1rem;
          font-weight: 400;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &:focus {
        outline: none;
      }

      .sft-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        h3 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-left: 9px;
        }
      }
    }
  }
}

.token-info {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  width: 100%;
  justify-content: space-between;

  .token-amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;

    span {
      font-size: 0.8rem;
      font-weight: 400;
      text-align: right;
    }

    input {
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
      text-align: right;
      border: none;
      outline: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        display: none;
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap;
  }
}

.no-sfts {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  box-shadow:
    1px 1px 6px rgba(217, 217, 217, 0.65),
    -1px -1px 6px rgba(217, 217, 217, 0.5);
  border-radius: 5px;
  padding: 10px 13px;
  margin-bottom: 37px;

  &__button {
    margin-left: auto;
    transition: color 0.3s;
  }
}

.import-btn {
  width: 228px;
  margin: 0 auto;
  display: block;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.0625rem solid var(--theme-main-color);
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 8px 6px 10px 6px;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-sizing: border-box;

  .user-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: gray;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.2rem;

    h3 {
      font-size: 1rem;
      font-weight: 500;
    }

    p {
      font-size: 0.8rem;
      font-weight: 400;
    }
  }
}

.connected-wallet {
  width: 100%;
  text-align: right;
}

.unlock-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .unlock-title {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;

    h4 {
      font-size: 1.2rem;
      font-weight: 500;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .unlock-btns {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 400px;

    @media screen and (max-width: 500px) {
      width: 280px;
    }

    .connect-btn {
      box-sizing: border-box;
      margin: 0.3rem;
      background-color: #1e88e5;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      margin-top: 10px;

      &:hover {
        background-color: #1565c0;
      }

      &:active {
        background-color: #0d47a1;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.hello-user {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;

  .hello-text {
    font-size: 1rem;
    font-weight: 500;
    margin-right: 0.5rem;
  }
}

.error {
  text-align: center;
  line-height: 3rem;
  // soft red color
  h1 {
    color: #ff5252;
  }
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  img {
    width: 100px;
    height: 100px;
  }
}

.justify-unset {
  justify-content: unset !important;
}

.nft-container {
  display: flex;
  flex-direction: column;
}

.flex-row {
  flex-direction: row !important;
}

.nft-pagination-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  .nft-page-btn {
    padding: 5px 10px;
    font-size: 14px;
    margin-top: 20px;
  }
}
