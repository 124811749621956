.loader {
  position: relative;
  display: flex;
  width: to-rem(70);
  height: to-rem(70);
  align-items: center;
  justify-content: center;
}

.loader::after,
.loader::before {
  position: absolute;
  width: to-rem(50);
  height: to-rem(50);
  box-sizing: border-box;
  border: 5px solid var(--theme-main-color);
  animation: animloader 2s linear infinite;
  border-radius: 50%;
  content: "";
}

.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.loader-small {
  width: to-rem(50);
  height: to-rem(50);
}

.loader-small::after,
.loader-small::before {
  width: to-rem(30);
  height: to-rem(30);
}

.loader-ultra-small {
  width: to-rem(40);
  height: to-rem(40);
}

.loader-ultra-small::after,
.loader-ultra-small::before {
  width: to-rem(20);
  height: to-rem(20);
}
