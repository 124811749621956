.dapp-core-component__walletConnectLoginContainerStyles__xPortal-pairings {
  display: none !important;
}

.dapp-core-component__main__card {
  background-color: transparent !important;
  border: none !important;
  margin: 0 !important;

  .dapp-core-component__main__card-body{
    display: flex;
    flex-direction: column;
  }

  .dapp-core-component__main__btn {
    @extend .btn;
    @extend .btn-primary;

    &:focus{
      @extend .btn;
      @extend .btn-primary;
      box-shadow: none;
    }
  }

  .dapp-metamask-proxy-login{
    display: none;
  }
}